const actions = {

  READ_SUBJECTS_BEGIN : 'READ_SUBJECTS_BEGIN',
  READ_SUBJECTS_SUCCESS:'READ_SUBJECTS_SUCCESS',
  READ_SUBJECTS_ERR : 'READ_SUBJECTS_ERR',  
  
  READ_UNITS_BEGIN : 'READ_UNITS_BEGIN',
  READ_UNITS_SUCCESS:'READ_UNITS_SUCCESS',
  READ_UNITS_ERR : 'READ_UNITS_ERR',  


  readSubjectsBegin: () => {
    return {
      type: actions.READ_SUBJECTS_BEGIN,
    };
  },

  readSubjectsSuccess: (data) => {
    return {
      type: actions.READ_SUBJECTS_SUCCESS,
      data,
    };
  },

  readSubjectsErr: (err) => {
    return {
      type: actions.READ_SUBJECTS_ERR,
      err,
    };
  },

  readUnitsBegin: () => {
    return {
      type: actions.READ_UNITS_BEGIN,
    };
  },

  readUnitsSuccess: (data) => {
    return {
      type: actions.READ_UNITS_SUCCESS,
      data,
    };
  },

  readUnitsErr: (err) => {
    return {
      type: actions.READ_UNITS_ERR,
      err,
    };
  },

};

export default actions;
