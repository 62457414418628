import React, { useEffect, useState } from 'react';
import { NoteCardWrap } from '../note/style';
import { Col, Collapse, Modal, Row, Spin, Table, Tooltip } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Cards } from '../../components/cards/frame/cards-frame';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentTopicsByUnitId } from '../../redux/topics/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import topicReadBadge from '../../static/img/TOPIC-READ/TOPIC-READ-16X16.png';
import topicCompleteBadge from '../../static/img/TOPIC-COMPLETE/TOPIC-COMPLETE-16X16.png';
import { setItem } from '../../utility/localStorageControl';
import { GetAttemptedQ } from '../../redux/generateQuestions/actionCreator';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilMinus from '@iconscout/react-unicons/icons/uil-minus';
import { Button } from '../../components/buttons/buttons';

const { Panel } = Collapse;

function TopicsList() {
    const { unitId } = useParams()
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { UnitTopics, topicsLoading, AttemptedQA, AttemptedQALoading } = useSelector((state) => {
        return {
            UnitTopics: state.studentTopicsByUnit.data,
            topicsLoading: state.studentTopicsByUnit.loading,
            AttemptedQA: state.AttemptedQA.data,
            AttemptedQALoading: state.AttemptedQA.loading,
        };
    });

    useEffect(() => {
        if (unitId !== undefined || unitId !== ':unitId') {
            dispatch(getStudentTopicsByUnitId(parseInt(unitId)));
        }
    }, [unitId]);

    const GetQA = (topicId) => {
        dispatch(GetAttemptedQ(topicId))
        setIsModalVisible(true)
    }

    const TopicsColumns = [
        {
            title: 'Topic',
            dataIndex: 'name',
            key: 'name',
            align: 'left'
        },
        {
            title: 'Grade',
            dataIndex: 'QuizScore',
            key: 'QuizScore',
            align: 'center',
        },
        {
            title: 'Badge',
            dataIndex: 'badge',
            key: 'badge',
            align: 'center',
        },
        {
            title: 'Earned On',
            dataIndex: 'earnedon',
            key: 'earnedon',
        }        
    ];

    const matchrows = [];


    UnitTopics !== null && Object.keys(UnitTopics).length !== 0 && UnitTopics.topics.length !== 0 && UnitTopics.topics.sort((a, b) => (a.status < b.status) ? -1 : 1).map((obj, index) => {
        const { id, name, pdf, status, testtopicId, videodescription, videourl, QuizAttemptData, QaAttemptData } = obj;
        const row = {
            key: index,
            id,
            name:
                status !== 'CONTENT FREEZED' ? (
                    <Tooltip title={'Content not found'} placement="right"><span style={{ color: '#bbb' }} className="">{name}</span></Tooltip>
                ) : (
                    <Link to={`topic/${id}`} onClick={() => setItem('activestudenttopic', index)} className="underline-hover">
                        {name}
                    </Link>
                ),
            pdf,
            QuizScore: QuizAttemptData !== null && QuizAttemptData.payload.score === -1 ? '-' : `${QuizAttemptData.payload.score} of ${QuizAttemptData.payload.totalquestions}`,
            status,
            testtopicId,
            videodescription,
            videourl,
            badge: (QuizAttemptData !== null && <img src={topicReadBadge} className={`${QuizAttemptData.payload.badge !== 1 ? 'img-grayscale' : ''}`} />),
            earnedon: <i className='fontsize-13'>{(QuizAttemptData != null && QuizAttemptData.payload !== null && QuizAttemptData.payload.badge !== 0 && QuizAttemptData.payload.lastsubmitted !== null) ?
                new Date(QuizAttemptData.payload.lastsubmitted).toDateString()
                :
                '-'
            }</i>            
        };
        matchrows.push(row);
    });

    const sortedData = matchrows

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <Cards headless >
                <div className='d-flex mb-20'>
                    <h3> {UnitTopics !== null && UnitTopics.unitName}</h3>
                    &nbsp;&nbsp;
                    <span>| {UnitTopics !== null && UnitTopics.topics.length} Topics </span>
                </div>
                <NoteCardWrap>
                    {
                        topicsLoading ?
                            <div className='custom-spin'>
                                <CustomSpinner />
                            </div>
                            :
                            <div>
                                <span className='d-flex justify-content-end fontsize-12 mb-10'>Please note that the data you are viewing is cached and updated every 5 minutes</span>
                                {
                                    UnitTopics !== null && Object.keys(UnitTopics).length !== 0 && UnitTopics.topics.length !== 0 ?
                                        <Table columns={TopicsColumns} dataSource={sortedData} pagination={false} />
                                        :
                                        <div className='text-center' style={{ fontSize: '20px' }}>
                                            <p>Topics not found</p>
                                        </div>
                                }
                            </div>
                    }
                </NoteCardWrap>
            </Cards>
            <Modal
                title={`QA of ${!AttemptedQALoading && AttemptedQA !== null && AttemptedQA.attempt1 !== null && AttemptedQA.attempt1.data.length !== 0 && AttemptedQA.attempt1.data.length !== 0 ? AttemptedQA.attempt1.data[0].topicName : ''}`}
                open={isModalVisible}
                onCancel={handleModalCancel}
                width={1200}
                footer={<></>}
            >
                {
                    AttemptedQALoading ?
                        <div className='pt-20 pb-20 d-flex justify-content-center'>
                            <CustomSpinner />
                        </div>
                        :
                        <div>
                            <div className='d-flex justify-content-between align-center-v mb-20'>
                                <h2 className='m-0'>Average Score : {AttemptedQA !== null && AttemptedQA.attempt1 !== null && AttemptedQA.attempt1.totalScore}</h2>
                                <div className='fontsize-12'>AvgScore = (QAScore1 + QAScore2)/2</div>
                            </div>
                            {
                                AttemptedQA !== null && AttemptedQA.attempt1 !== null && AttemptedQA.attempt1.data.length !== 0 && AttemptedQA.attempt1.data.map((obj, index) => <CollapseComponent QAObj={obj} index={index} />)
                            }
                        </div>
                }
            </Modal>
        </div>
    );
}

export default TopicsList

const CollapseComponent = ({ QAObj, index }) => {
    return (
        <div>
            <Collapse defaultActiveKey={['1']} bordered={false} expandIcon={({ isActive }) => (isActive ? <UilMinus /> : <UilPlus />)} className='m-0 mb-5' >
                <Panel header={<div className='d-flex justify-content-between'><p className='m-0'>{index + 1 + '. ' + QAObj.question}</p><p className='fontsize-13'>Score: {QAObj.score} | {new Date(QAObj.submittedOn).toLocaleString()}</p></div>} key={index + 1}>
                    <h4>Student Answer</h4>
                    <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {QAObj.userAnswer}
                    </p>
                    <h4>Evaluation Feedback1</h4>
                    <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {QAObj.description1 === null ? 'NA' : QAObj.description1}
                    </p>
                    <div className='mb-20'>{QAObj.description1 !== null && "Evaluation Score1: " + QAObj.evaluationscore1}</div>
                    <h4>Evaluation Feedback2</h4>
                    <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {QAObj.description2 === null ? 'NA' : QAObj.description2}
                    </p>
                    <div className='mb-20'>{QAObj.description2 !== null && "Evaluation Score2: " + QAObj.evaluationscore2}</div>
                    <h4>Evaluation Feedback3</h4>
                    <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {QAObj.description3 === null ? 'NA' : QAObj.description3}
                    </p>
                    <div className='mb-20'>{QAObj.description3 !== null && "Evaluation Score3: " + QAObj.evaluationscore3}</div>
                    <div className='mt-20 mb-20 fontsize-12'>Note: QAScore = (EvaluationScore1 + EvaluationScore2 + EvaluationScore3)/3</div>
                </Panel>
            </Collapse>
        </div>
    );
}