import React from 'react';
import { FullScreenToggle, Main } from '../styled';
import { NoteNav, NoteWrapper } from '../note/style';
import { Alert, Col, Row, Spin, Tooltip } from 'antd';
import { Link, NavLink, Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useLayoutEffect } from 'react';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import UilAngleLeftB from '@iconscout/react-unicons/icons/uil-angle-left-b';
import UilAngleRightB from '@iconscout/react-unicons/icons/uil-angle-right-b';
import UilPlayCircle from '@iconscout/react-unicons/icons/uil-play-circle';
import UilBookOpen from '@iconscout/react-unicons/icons/uil-book-open';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import UilFeedback from '@iconscout/react-unicons/icons/uil-feedback';
import UilHunting from '@iconscout/react-unicons/icons/uil-hunting';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { FixedSidebar, SidebarWrap } from '../teacher/styles/sidebarstyle';
import { Suspense } from 'react';
import ContentPage from './ContentPage'
import VideoPage from './VideoPage'
import { useDispatch, useSelector } from 'react-redux';
import { getSubjectById } from '../../redux/teacherSubjectsUnits/actionCreator';
import { useEffect } from 'react';
import { getStudentTopicsByUnitId } from '../../redux/topics/actionCreator';
import ContentFeedback from './ContentFeedback';
import Quiz from './Quiz';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import UilAngleDoubleLeft from '@iconscout/react-unicons/icons/uil-angle-double-left';
import UilAngleDoubleRight from '@iconscout/react-unicons/icons/uil-angle-double-right';
import topicReadBadge from '../../static/img/TOPIC-READ/TOPIC-READ-32X32.png'
import { getItem, setItem } from '../../utility/localStorageControl';

function TopicContent() {

    const { subjectId, unitId, topicId } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedActiveTopic, setSelectedActiveTopic] = useState(-1)

    // Quiz page
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showQuiz, setShowQuiz] = useState(false)

    const [fullScreen, setFullscreen] = useState(false)

    const { SubjectInfo, subjectLoading, UnitTopicsContent, topicsLoading } = useSelector((state) => {
        return {
            SubjectInfo: state.Subject.data,
            subjectLoading: state.Subject.loading,
            UnitTopicsContent: state.studentTopicsByUnit.data,
            topicsLoading: state.studentTopicsByUnit.loading,
        };
    });

    useEffect(() => {
        let activeTopic = getItem('activestudenttopic')
        if (activeTopic !== undefined) {
            setSelectedActiveTopic(activeTopic)
        }
        else {
            setSelectedActiveTopic(0)
        }
    }, [])

    useEffect(() => {
        dispatch(getSubjectById(subjectId))
        dispatch(getStudentTopicsByUnitId(unitId))
    }, [dispatch, subjectId])

    const Topics = []

    UnitTopicsContent !== null && Object.keys(UnitTopicsContent).length !== 0 && UnitTopicsContent.topics.length !== 0 && UnitTopicsContent.topics.map((obj, index) => {
        const { id, name, pdf, status, testtopicId, videodescription, videourl, QuizAttemptData, refvideourl, refvideodescription } = obj
        status === "CONTENT FREEZED" &&
            Topics.push({
                key: index,
                id,
                name,
                pdf,                
                // QuizScore: QuizAttemptData !== null && QuizAttemptData.score === -1 ? '-' : QuizAttemptData.score + '/' + QuizAttemptData.totalquestions,
                status,
                testtopicId,
                videodescription,
                videourl,
                refvideourl,
                refvideodescription,
                // badge: <img src={topicReadBadge} className={`${QuizAttemptData.badge !== 1 ? 'img-grayscale' : ''}`} />
            })
    })

    const [sideBarState, setSidebarState] = useState({
        responsive: 0,
        collapsed: false,
        visible: false,
        modalType: 'primary',
    });

    const { responsive, collapsed } = sideBarState;

    useLayoutEffect(() => {
        function updateSize() {
            const width = window.innerWidth;
            setSidebarState({ responsive: width });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    const toggleCollapsed = () => {
        setSidebarState({
            ...sideBarState,
            collapsed: !collapsed,
        });
    };

    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(0);

    const gotoPreviousTopic = () => {
        if (selectedActiveTopic !== 0) {
            setPageNumber(0)
            setNumPages(0)
            setItem('activestudenttopic', selectedActiveTopic - 1)
            setSelectedActiveTopic(selectedActiveTopic - 1)
            let topicId = Topics.length !== 0 && selectedActiveTopic >= 0 && Topics[parseInt(selectedActiveTopic) - 1].id
            navigate(`/student/subject/${subjectId}/unit/${unitId}/topic/${topicId}/content`)
        }
    }

    const gotoNextTopic = () => {
        const length = Topics.length !== 0 && Topics.length
        if (selectedActiveTopic !== length - 1) {
            setPageNumber(0)
            setNumPages(0)
            setItem('activestudenttopic', parseInt(selectedActiveTopic) + 1)
            setSelectedActiveTopic(parseInt(selectedActiveTopic) + 1)
            let topicId = Topics.length !== 0 && selectedActiveTopic >= 0 && Topics[parseInt(selectedActiveTopic) + 1].id
            navigate(`/student/subject/${subjectId}/unit/${unitId}/topic/${topicId}/content`)
        }
    }

    return (
        <div>
            <Main className='mt-30'>
                <NoteWrapper>
                    {
                        subjectLoading || topicsLoading ?
                            <div className="">
                                <CustomSpinner />
                            </div>
                            :
                            <Row gutter={25}>
                                <Col xxl={fullScreen ? 0 : 6} lg={fullScreen ? 0 : 8} md={fullScreen ? 0 : 10} xs={24}>
                                    {responsive <= 767 && (
                                        <div className="sidebar-trier-wrap text-center">                                           
                                            <div className='mb-10 p-15 radius-8'>
                                                <h3 className=''>
                                                    <Link to={`/student/subject/${subjectId}/unit/${unitId}`} style={{ textDecoration: 'underline' }} >{SubjectInfo !== null && SubjectInfo.name}</Link>
                                                    <span>{' > '}</span>
                                                    {/* <Link to={`/student/subject/${subjectId}/unit/${unitId}`} style={{ color: '#000', textDecoration: 'underline' }} className='fontsize-14' >{UnitTopicsContent !== null && UnitTopicsContent.unitName}</Link> */}
                                                    <span className='fontsize-14 m-0'>{UnitTopicsContent !== null && UnitTopicsContent.unitName}</span>
                                                </h3>
                                                <div className='d-flex align-center-v justify-content-between'>
                                                    {
                                                        parseInt(selectedActiveTopic) > 0 ?
                                                            <div className='cursor-pointer align-center-v' onClick={() => { gotoPreviousTopic() }}><UilAngleLeftB /></div>
                                                            :
                                                            <div style={{ width: '10px' }}></div>
                                                    }
                                                    {
                                                        parseInt(selectedActiveTopic) >= 0 &&
                                                        <div>
                                                            <div className='text-center m-0 fontsize-20' style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 500 }}>
                                                                <Tooltip placement='top' title={Topics.length !== 0 && parseInt(selectedActiveTopic) >= 0 && Topics[parseInt(selectedActiveTopic)].name}>
                                                                    {Topics.length !== 0 && parseInt(selectedActiveTopic) >= 0 && Topics[parseInt(selectedActiveTopic)].name}
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        parseInt(selectedActiveTopic) !== (Topics.length !== 0 && Topics.length - 1) ?
                                                            <div className='cursor-pointer align-center-v' onClick={() => { gotoNextTopic() }}><UilAngleRightB /></div>
                                                            :
                                                            <div style={{ width: '10px' }}></div>
                                                    }
                                                </div>
                                                <div>
                                                    <ul className="d-flex mt-20 justify-content-between">
                                                        <li>
                                                            <NavLink to='content' style={{ fontSize: '16px' }} className='align-center-v'>
                                                                <UilBookOpen /> Read Content
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to='video' style={{ fontSize: '16px' }} className='align-center-v'>
                                                                <UilPlayCircle /> Watch Video
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to='quiz' style={{ fontSize: '16px' }} className='align-center-v' onClick={() => { setCurrentQuestionIndex(0); setShowQuiz(false) }}>
                                                                <UilHunting /> Take Quiz
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to='feedback' style={{ fontSize: '16px' }} className='align-center-v'>
                                                                <UilFeedback /> Feedback
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {responsive > 767 ? (
                                        <div className="sidebar-card">
                                            <Cards title={<Link to={`/student/subject/${subjectId}/unit/${unitId}`} style={{ textDecoration: 'underline' }} >{SubjectInfo !== null && SubjectInfo.name}</Link>} className='text-center'>
                                                <div className='d-flex justify-content-between align-center-v' style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                                    {
                                                        parseInt(selectedActiveTopic) > 0 ?
                                                            <div className='cursor-pointer align-center-v' onClick={() => { gotoPreviousTopic(); }}><UilAngleLeftB /></div>
                                                            :
                                                            <div style={{ width: '10px' }}></div>
                                                    }
                                                    {
                                                        parseInt(selectedActiveTopic) >= 0 &&
                                                        <div>
                                                            <h3 className='text-center m-0' style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                <Tooltip placement='top' title={Topics.length !== 0 && parseInt(selectedActiveTopic) >= 0 && Topics[parseInt(selectedActiveTopic)].name}>
                                                                    {Topics.length !== 0 && parseInt(selectedActiveTopic) >= 0 && Topics[parseInt(selectedActiveTopic)].name}
                                                                </Tooltip>
                                                            </h3>
                                                        </div>
                                                    }
                                                    {
                                                        parseInt(selectedActiveTopic) !== (Topics.length !== 0 && Topics.length - 1) ?
                                                            <div className='cursor-pointer align-center-v' onClick={() => { gotoNextTopic() }}><UilAngleRightB /></div>
                                                            :
                                                            <div style={{ width: '10px' }}></div>
                                                    }
                                                </div>
                                                <div className="note-sidebar-bottom">
                                                    <NoteNav>
                                                        <ul className="ninjadash-taskApp-sidebar__nav">
                                                            <li>
                                                                <NavLink to='content' style={{ lineHeight: '40px', fontSize: '18px' }}>
                                                                    <UilBookOpen /> Read Content
                                                                </NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink to='video' style={{ lineHeight: '40px', fontSize: '18px' }}>
                                                                    <UilPlayCircle /> Watch Video
                                                                </NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink to='quiz' style={{ lineHeight: '40px', fontSize: '18px' }} onClick={() => { setCurrentQuestionIndex(0); setShowQuiz(false) }}>
                                                                    <UilHunting /> Take Quiz
                                                                </NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink to='feedback' style={{ lineHeight: '40px', fontSize: '18px' }}>
                                                                    <UilFeedback /> Feedback
                                                                </NavLink>
                                                            </li>
                                                        </ul>
                                                    </NoteNav>
                                                </div>
                                            </Cards>
                                        </div>
                                    ) : (
                                        <FixedSidebar className={collapsed ? 'show' : 'hide'}>
                                            {/* <Link to="#" type="link" className="trigger-close" onClick={toggleCollapsed}>
                                                <UilTimes />
                                            </Link>
                                            <SidebarWrap className="mb-30">
                                                <div className="ninjadash-taskApp-sidebar">
                                                    <Heading as='h3' className='text-center'>Topics</Heading>
                                                    <ul className="ninjadash-taskApp-sidebar__nav">
                                                        <li className="ninjadash-taskApp-sidebar__nav--item">
                                                            <NavLink className="ninjadash-taskApp-sidebar__nav--link" to='content' style={{ fontSize: '16px' }}>
                                                                <UilBookOpen size={16} />&nbsp;Content
                                                            </NavLink>
                                                        </li>
                                                        <li className="ninjadash-taskApp-sidebar__nav--item">
                                                            <NavLink className="ninjadash-taskApp-sidebar__nav--link" to='video' style={{ fontSize: '16px' }}>
                                                                <UilPlayCircle size={16} />&nbsp;Video
                                                            </NavLink>
                                                        </li>
                                                        <li className="ninjadash-taskApp-sidebar__nav--item">
                                                            <NavLink className="ninjadash-taskApp-sidebar__nav--link" to='quiz' style={{ fontSize: '16px' }}>
                                                                <UilHunting size={16} />&nbsp;Quiz
                                                            </NavLink>
                                                        </li>
                                                        <li className="ninjadash-taskApp-sidebar__nav--item">
                                                            <NavLink className="ninjadash-taskApp-sidebar__nav--link" to='feedback' style={{ fontSize: '16px' }}>
                                                                <UilFeedback size={16} />&nbsp;Feedback
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </SidebarWrap> */}
                                        </FixedSidebar>
                                    )}
                                </Col>
                                <Col xxl={fullScreen ? 24 : 18} lg={fullScreen ? 24 : 16} md={fullScreen ? 24 : 14} xs={24}>
                                    <Suspense
                                        fallback={
                                            <div className="">
                                                <CustomSpinner />
                                            </div>
                                        }
                                    >
                                        {
                                            responsive > 767 &&
                                            <FullScreenToggle>
                                                <div className=''>
                                                    {
                                                        fullScreen ?
                                                            <UilAngleDoubleRight onClick={() => setFullscreen(false)} className='cursor-pointer align-center-v' />
                                                            :
                                                            <UilAngleDoubleLeft onClick={() => setFullscreen(true)} className='cursor-pointer align-center-v' />
                                                    }
                                                </div>
                                            </FullScreenToggle>
                                        }
                                        <Routes>
                                            <Route path="/" element={<Navigate to={`content`} replace={true} />} />
                                            <Route path="content" element={<ContentPage topicsLoading={topicsLoading} setPageNumber={setPageNumber} pageNumber={pageNumber} setNumPages={setNumPages} numPages={numPages} pdf={Topics.length !== 0 && selectedActiveTopic >= 0 && Topics[selectedActiveTopic].pdf} topicName={Topics.length !== 0 && selectedActiveTopic >= 0 && Topics[selectedActiveTopic].name} />} />
                                            <Route path="video" element={<VideoPage topicsLoading={topicsLoading} refVideoURL={Topics.length !== 0 && selectedActiveTopic >= 0 && Topics[selectedActiveTopic].refvideourl} refVideoDescription={Topics.length !== 0 && selectedActiveTopic >= 0 && Topics[selectedActiveTopic].refvideodescription} topicName={Topics.length !== 0 && selectedActiveTopic >= 0 && Topics[selectedActiveTopic].name} />} />
                                            <Route path="quiz" element={<Quiz topicId={Topics.length !== 0 && selectedActiveTopic >= 0 && Topics[selectedActiveTopic].id} topicName={Topics.length !== 0 && selectedActiveTopic >= 0 && Topics[selectedActiveTopic].name} setCurrentQuestionIndex={setCurrentQuestionIndex} currentQuestionIndex={currentQuestionIndex} showQuiz={showQuiz} setShowQuiz={setShowQuiz} subjectName={SubjectInfo !== null && SubjectInfo.name} />} />
                                            <Route path="feedback" element={<ContentFeedback topicId={Topics.length !== 0 && selectedActiveTopic >= 0 && Topics[selectedActiveTopic].id} topicName={Topics.length !== 0 && selectedActiveTopic >= 0 && Topics[selectedActiveTopic].name} />} />
                                        </Routes>
                                    </Suspense>
                                </Col>
                            </Row>
                    }
                </NoteWrapper>
            </Main>
        </div>
    );
}

export default TopicContent;