import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message } from 'antd';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';


const {
  transcribeBegin,
  transcribeSuccess,
  transcribeErr,
} = actions;

const TranscribeVideotoText = (formData) => {  
  return async (dispatch) => {
    try {
      await dispatch(transcribeBegin());                 
      const response = await DataService.post('/transcribe', formData);
      const token = getItem('access_token');
      const data = response.data      
      if (token && data !== null && data.Error === false) {
        await dispatch(transcribeSuccess(data));        
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(transcribeSuccess(null));
          dispatch(logOut())
        }
        if (Object.keys(data).length >= 3 && data.statusCode == 500) {
          message.error(data.message)
          await dispatch(transcribeSuccess(null));
        }
        else {
          await dispatch(transcribeSuccess(data));
        }
      }
      else {
        await dispatch(transcribeErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(transcribeErr(err));
    }
  };
};




export {
  TranscribeVideotoText,
};
