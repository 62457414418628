import { 
    readAnswersBegin, 
    readAnswersSuccess, 
    readAnswersError, 
    manualEvaluationBegin, 
    manualEvaluationSuccess, 
    manualEvaluationError,
    readQaReportsBegin,
    readQaReportsSuccess,
    readQaReportsError
 } from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { message } from 'antd';
import { getItem } from '../../utility/localStorageControl';


export const getAnswersData = (regId, yearsemId, branchId, topicId) => {
    return async (dispatch) => {
        try {
            await dispatch(readAnswersBegin());
            const response = await DataService.get(`/studentmaster/studentAnsByParams/${regId}/${yearsemId}/${branchId}/${topicId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data.Error === false) {
                await dispatch(readAnswersSuccess(data.payload));
            }
            else if (data !== null && data.Error === true) {
                if (Object.keys(data).length >= 3 && data.statusCode == 401) {
                    message.error(data.message)
                    await dispatch(readAnswersSuccess([]));
                    dispatch(logOut())
                }
                else {
                    await dispatch(readAnswersSuccess([]));
                }
            }
            else {
                await dispatch(readAnswersSuccess([]));
            }
        } catch (error) {
            await dispatch(readAnswersError(error));
        }
    };
};

export const EvaluateAnswersManually = (userId, topicId) => {
    return async (dispatch) => {
        try {
            const postData = { userId: userId, topicId: topicId }
            await dispatch(manualEvaluationBegin());
            const response = await DataService.post(`qa-attempts/singleEval`, postData);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data.Error === false) {
                await dispatch(manualEvaluationSuccess(data));
            }
            else if (data !== null && data.Error === true) {
                if (Object.keys(data).length >= 3 && data.statusCode == 401) {
                    message.error(data.message)
                    await dispatch(manualEvaluationSuccess(null));
                    dispatch(logOut())
                }
                else {
                    await dispatch(manualEvaluationSuccess(null));
                }
            }
            else {
                await dispatch(manualEvaluationSuccess(null));
            }
        } catch (error) {
            await dispatch(manualEvaluationError(error));
        }
    };
};

export const getQaReports = (regId, yearsemId, branchId, unitId) => {
    return async (dispatch) => {
        try {
            await dispatch(readQaReportsBegin());
            const response = await DataService.get(`/studentmaster/studentResultByUnitId/${regId}/${yearsemId}/${branchId}/${unitId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data.Error === false) {
                await dispatch(readQaReportsSuccess(data.payload));
            }
            else if (data !== null && data.Error === true) {
                if (Object.keys(data).length >= 3 && data.statusCode == 401) {
                    message.error(data.message)
                    await dispatch(readQaReportsSuccess([]));
                    dispatch(logOut())
                }
                else {
                    await dispatch(readQaReportsSuccess([]));
                }
            }
            else {
                await dispatch(readQaReportsSuccess([]));
            }
        } catch (error) {
            await dispatch(readQaReportsError(error));
        }
    };
};