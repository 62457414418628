import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';
const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Topic Scheduled Successfully',
  });
};

const {
  getNgrokURLBegin,
  getNgrokURLSuccess,
  getNgrokURLErr

} = actions;

const getNgrokURL = () => {
  return async (dispatch) => {
    try {            
      await dispatch(getNgrokURLBegin());
      const query = await DataService.get(`/tesselator/ngrokurl`);
      const token = getItem('access_token');            
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(getNgrokURLSuccess(query.data.payload));
      } else {
        await dispatch(getNgrokURLSuccess(null));
      }
    } catch (err) {
      await dispatch(getNgrokURLErr(err));
    }
  };
};

export {  
  getNgrokURL
};
