const actions = {

  READ_TOPICS_BEGIN: 'READ_TOPICS_BEGIN',
  READ_TOPICS_SUCCESS: 'READ_TOPICS_SUCCESS',
  READ_TOPICS_ERR: 'READ_TOPICS_ERR',
  
  READ_TOPIC_CONTENT_BEGIN: 'READ_TOPIC_CONTENT_BEGIN',
  READ_TOPIC_CONTENT_SUCCESS: 'READ_TOPIC_CONTENT_SUCCESS',
  READ_TOPIC_CONTENT_ERR: 'READ_TOPIC_CONTENT_ERR',
  
  FLAG_UPDATE_BEGIN: 'FLAG_UPDATE_BEGIN',
  FLAG_UPDATE_SUCCESS: 'FLAG_UPDATE_SUCCESS',
  FLAG_UPDATE_ERR: 'FLAG_UPDATE_ERR',
  
  UPDATE_CONTENT_BEGIN: 'UPDATE_CONTENT_BEGIN',
  UPDATE_CONTENT_SUCCESS: 'UPDATE_CONTENT_SUCCESS',
  UPDATE_CONTENT_ERR: 'UPDATE_CONTENT_ERR',
  
  DELETE_CONTENT_BEGIN: 'DELETE_CONTENT_BEGIN',
  DELETE_CONTENT_SUCCESS: 'DELETE_CONTENT_SUCCESS',
  DELETE_CONTENT_ERR: 'DELETE_CONTENT_ERR',


  ReadTopicsBegin: () => {
    return {
      type: actions.READ_TOPICS_BEGIN,
    };
  },

  ReadTopicsSuccess: (data) => {    
    return {
      type: actions.READ_TOPICS_SUCCESS,      
      data,
    };
  },

  ReadTopicsErr: (err) => {
    return {
      type: actions.READ_TOPICS_ERR,
      err,
    };
  },
  
  ReadTopicContentBegin: () => {
    return {
      type: actions.READ_TOPIC_CONTENT_BEGIN,
    };
  },

  ReadTopicContentSuccess: (data) => {    
    return {
      type: actions.READ_TOPIC_CONTENT_SUCCESS,      
      data,
    };
  },

  ReadTopicContentErr: (err) => {
    return {
      type: actions.READ_TOPIC_CONTENT_ERR,
      err,
    };
  },
  
  flagUpdateBegin: () => {
    return {
      type: actions.FLAG_UPDATE_BEGIN,
    };
  },

  flagUpdateSuccess: (data) => {    
    return {
      type: actions.FLAG_UPDATE_SUCCESS,      
      data,
    };
  },

  flagUpdateErr: (err) => {
    return {
      type: actions.FLAG_UPDATE_ERR,
      err,
    };
  },
  
  UpdateContentBegin: () => {
    return {
      type: actions.UPDATE_CONTENT_BEGIN,
    };
  },

  UpdateContentSuccess: (data) => {    
    return {
      type: actions.UPDATE_CONTENT_SUCCESS,      
      data,
    };
  },

  UpdateContentErr: (err) => {
    return {
      type: actions.UPDATE_CONTENT_ERR,
      err,
    };
  },
  
  DeleteContentBegin: () => {
    return {
      type: actions.DELETE_CONTENT_BEGIN,
    };
  },

  DeleteContentSuccess: (data) => {    
    return {
      type: actions.DELETE_CONTENT_SUCCESS,      
      data,
    };
  },

  DeleteContentErr: (err) => {
    return {
      type: actions.DELETE_CONTENT_ERR,
      err,
    };
  },
};

export default actions;
