import react from 'react'
import { Main } from '../styled'
import { Cards } from '../../components/cards/frame/cards-frame'
import { FormValidationWrap } from '../forms/overview/Style'
import { Col, Form, Input, Row, message } from 'antd';
import { Button } from '../../components/buttons/buttons';
import { NoteCardWrapWithHeader } from '../note/style';
import { TranscribeVideotoText } from '../../redux/transcribe/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import actions from '../../redux/transcribe/actions';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { useState } from 'react';

const { transcribeSuccess } = actions

function Transcribe() {
    const [form] = Form.useForm();

    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)

    const { TranscribeRes, isTranscribing } = useSelector((state) => {
        return {
            TranscribeRes: state.TranscribeRes.data,
            isTranscribing: state.TranscribeRes.loading,
        };
    });

    useEffect(() => {
        dispatch(transcribeSuccess(null))
    }, [])

    const handleFormSubmit = (formData) => {
        setIsLoading(true)
        if (formData.videourl !== "") {
            dispatch(TranscribeVideotoText(formData));
        }
        else {
            message.error('Video URL should not be empty');
        }
    }

    useEffect(() => {
        if (TranscribeRes !== null && TranscribeRes.Error === true) {
            setTimeout(() => {
                setIsLoading(false);
                message.error(TranscribeRes.message)
                dispatch(transcribeSuccess(null))
            }, 1500);
        }
        else {
            setTimeout(() => {
                setIsLoading(false);
            }, 1500);
        }
    }, [TranscribeRes])

    const DownloadContent = () => {
        if (TranscribeRes !== null && TranscribeRes.Error === false) {
            const element = document.createElement("a");
            const file = new Blob([TranscribeRes.payload], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = "TranscribedContent.txt";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
    }

    return (
        <div>
            <Main className='pt-20'>
                <Cards title="Transcribe Video to Text">
                    <NoteCardWrapWithHeader>
                        {
                            isLoading ?
                                <div className="custom-spin">
                                    <CustomSpinner Message={'Transcribing video to text. Please wait...'} />
                                </div>
                                :
                                <div>
                                    <FormValidationWrap>
                                        <Form name="transcribe-form" form={form} layout="vertical" onFinish={handleFormSubmit}>
                                            <Row gutter={25} className='align-center-v'>
                                                <Col xs={24} sm={24} md={20} lg={12}>
                                                    <Form.Item
                                                        name="url"
                                                        rules={[{ required: true, message: 'Video URL required!' }]}
                                                        label="Video URL"
                                                    >
                                                        <Input size="medium" type='url' placeholder="Paste video url here" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={4} lg={12}>
                                                    <div className='mt-5'>
                                                        <Button htmlType="submit" size={'default'} type="primary" >
                                                            Transcribe
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </FormValidationWrap>
                                    <div className=''>
                                        {
                                            TranscribeRes !== null && TranscribeRes.Error === false &&
                                            <div>
                                                <div className='d-flex justify-content-between mt-5'>
                                                    <h3>Transcribed Content</h3>
                                                    <div className='text-right'>
                                                        <Button htmlType="submit" size={'small'} type="primary" onClick={() => DownloadContent()} >
                                                            Download
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className='p-15'>
                                                    <div className='height-fixed-400px'>
                                                        <p id="content">
                                                            {TranscribeRes.payload}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                        }
                    </NoteCardWrapWithHeader>
                </Cards>
            </Main>
        </div>
    )
}

export default Transcribe 