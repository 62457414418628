import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message } from 'antd';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';


const {
  readSubjectsBegin,
  readSubjectsSuccess,
  readSubjectsErr,
  readUnitsBegin,
  readUnitsSuccess,
  readUnitsErr
} = actions;


const studentSubjectsDataRead = () => {
  return async (dispatch) => {
    try {      
      const fsId = getItem('fsId')
      await dispatch(readSubjectsBegin());
      const query = await DataService.get(`/subjectmaster/all/${fsId}`);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {
        await dispatch(readSubjectsSuccess(data.payload));
      }
      else {
        await dispatch(readSubjectsSuccess([]));
      }
    } catch (err) {
      await dispatch(readSubjectsErr(err));
    }
  };
};


const subjectUnitsDataRead = (subjectId) => {
  return async (dispatch) => {
    try {
      await dispatch(readUnitsBegin());
      const query = await DataService.get(`/unitmaster/topics/${subjectId}`);
      const token = getItem('access_token');
      const data = query.data;
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(readUnitsSuccess(query.data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(readUnitsSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(readUnitsSuccess([]));
        }
      }
      else {
        await dispatch(readUnitsSuccess([]));
      }
    } catch (err) {
      await dispatch(readUnitsErr(err));
    }
  };
};



export {
  studentSubjectsDataRead,
  subjectUnitsDataRead
};
