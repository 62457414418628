const actions = {
  FS_READ_BEGIN: 'FS_READ_BEGIN',
  FS_READ_SUCCESS: 'FS_READ_SUCCESS',
  FS_READ_ERR: 'FS_READ_ERR',

  fsReadBegin: () => {
    return {
      type: actions.FS_READ_BEGIN,
    };
  },

  fsReadSuccess: (data) => {
    return {
      type: actions.FS_READ_SUCCESS,
      data,
    };
  },

  fsReadErr: (err) => {
    return {
      type: actions.FS_READ_ERR,
      err,
    };
  },

};

export default actions;
