import React from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Col, Row, Skeleton } from 'antd';
import BadgesCount from './DashboardOverview/BadgesCount';
import { useDispatch, useSelector } from 'react-redux';
import { StudentBadgesRead } from '../../redux/studentBadgesEarned/actionCreator';
import { useEffect } from 'react';
import { Cards } from '../../components/cards/frame/cards-frame';
import { getItem, removeItem } from '../../utility/localStorageControl';
import NoticeModal from './DashboardOverview/NoticeModal';

function Dashboard() {

    const dispatch = useDispatch();

    useEffect(() => {
        removeItem('activestudenttopic')                
        const branchId=getItem('branchId')
        // dispatch(StudentBadgesRead());        
    }, [dispatch])

    const { StudentBadges, StudentBadgesLoading, StudentWeeklyTopics, StudenteeklyTopicsLoading } = useSelector((state) => {
        return {
            StudentBadges: state.StudentBadges.data,
            StudentBadgesLoading: state.StudentBadges.loading,
            StudentWeeklyTopics: state.StudentWeeklyTopics.data,
            StudenteeklyTopicsLoading: state.StudentWeeklyTopics.loading,
        };
    });

    return (
        <div>
            <Main>
                <PageHeader className="ninjadash-page-header-main" title="Dashboard" />
                <Row gutter={25}>
                    <Col xl={12} lg={24} md={24} xs={24}>
                        {
                            StudentBadgesLoading ?
                                <Cards headless>
                                    <Skeleton active />
                                </Cards>
                                :
                                <BadgesCount StudentBadges={StudentBadges} />
                        }
                    </Col>                    
                </Row>
                <NoticeModal/>
            </Main>
        </div >
    );
}

export default Dashboard;