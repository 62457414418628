const actions = {

  GET_STUDENT_INTERVIEWS_BEGIN: 'GET_STUDENT_INTERVIEWS_BEGIN',
  GET_STUDENT_INTERVIEWS_SUCCESS: 'GET_STUDENT_INTERVIEWS_SUCCESS',
  GET_STUDENT_INTERVIEWS_ERR: 'GET_STUDENT_INTERVIEWS_ERR',

  GET_INTERVIEW_ATTEMPTS_BEGIN: 'GET_INTERVIEW_ATTEMPTS_BEGIN',
  GET_INTERVIEW_ATTEMPTS_SUCCESS: 'GET_INTERVIEW_ATTEMPTS_SUCCESS',
  GET_INTERVIEW_ATTEMPTS_ERR: 'GET_INTERVIEW_ATTEMPTS_ERR',


  getStudentInterviewsBegin: () => {
    return {
      type: actions.GET_STUDENT_INTERVIEWS_BEGIN,
    };
  },

  getStudentInterviewsSuccess: (data) => {
    return {
      type: actions.GET_STUDENT_INTERVIEWS_SUCCESS,
      data,
    };
  },

  getStudentInterviewsErr: (err) => {
    return {
      type: actions.GET_STUDENT_INTERVIEWS_ERR,
      err,
    };
  },

  getInterviewAttemptsBegin: () => {
    return {
      type: actions.GET_INTERVIEW_ATTEMPTS_BEGIN,
    };
  },

  getInterviewAttemptsSuccess: (data) => {
    return {
      type: actions.GET_INTERVIEW_ATTEMPTS_SUCCESS,
      data,
    };
  },

  getInterviewAttemptsErr: (err) => {
    return {
      type: actions.GET_INTERVIEW_ATTEMPTS_ERR,
      err,
    };
  },

};

export default actions;
