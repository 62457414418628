import { Col, Row } from 'antd'
import React from 'react'

function Footer() {
    return (
        <div>
            <Row>
                <Col md={12} xs={24}>
                    <span className="admin-footer__copyright fontsize-12">
                        © {new Date().getFullYear()} Teleparadigm Networks Pvt. Ltd. All rights reserved
                    </span>
                </Col>
                <Col md={12} xs={24}>
                    <div className="admin-footer__links fontsize-12">
                        Version: 1.0
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Footer