import actions from './actions';

const {
  READ_SUBJECTS_BEGIN,
  READ_SUBJECTS_SUCCESS,
  READ_SUBJECTS_ERR,
  READ_UNITS_BEGIN,
  READ_UNITS_SUCCESS,
  READ_UNITS_ERR
} = actions;

const initialSubjectsState = {
  data: [],
  url: null,
  loading: false,
  error: null,
};

const initialUnitsState = {
  data: [],
  url: null,
  loading: false,
  error: null,
};

const ReadStudentSubjectsReducer = (state = initialSubjectsState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case READ_SUBJECTS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case READ_SUBJECTS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case READ_SUBJECTS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const ReadSubjectUnitsReducer = (state = initialUnitsState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case READ_UNITS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case READ_UNITS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case READ_UNITS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { ReadStudentSubjectsReducer, ReadSubjectUnitsReducer };
