import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
// import {
//   UilBooks,
//   UilBook,
// } from '@iconscout/react-unicons';
import { TopMenuStyle } from './Style';
import { useDispatch, useSelector } from 'react-redux';
import { studentSubjectsDataRead } from '../redux/studentSubjectsUnits/actionCreator';
// import { ellipsis } from '../utility/utility'
import { useState } from 'react';
import { useRef } from 'react';
import { removeItem } from '../utility/localStorageControl';
import { Tooltip } from 'antd';
import {
  UilBooks
} from '@iconscout/react-unicons';


function StudentMenu() {
  const path = '/student';

  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const [menuVisible, setMenuViible] = useState(false)

  const addParentActive = (event) => {
    removeItem('activeunit')
    removeItem('activestudenttopic')
    document.querySelectorAll('.parent').forEach((element) => {
      element.classList.remove('active');
    });

    const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
    const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
    if (!megaMenu) {
      event.currentTarget.closest('ul').previousSibling.classList.add('active');
      if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
    } else {
      event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
    }
  };

  useEffect(() => {
    if (dispatch) {
      dispatch(studentSubjectsDataRead());
    }
  }, [dispatch]);

  const { StudentSubjects } = useSelector((state) => {
    return {
      StudentSubjects: state.StudentSubjects.data,
      isLoading: state.StudentSubjects.loading,
    };
  });

  const toggleMegamenu = () => {
    setMenuViible(!menuVisible)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuViible(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <TopMenuStyle>
      <div className="ninjadash-top-menu">
        <ul>
          <li className="">
            <NavLink to={`${path}`} >
              Dashboard
            </NavLink>
          </li>

          <li className="has-subMenu" onClick={toggleMegamenu} ref={dropdownRef}>
            <Link to="#" className="" onClick={toggleMegamenu}>
              Learn
            </Link>
            {
              StudentSubjects.length !== 0 ?
                menuVisible &&
                <ul className="subMenu">
                  {
                    StudentSubjects.sort((a, b) => a.name > b.name ? 1 : -1).map((subjectObj, subjectIndex) =>
                      <li className="" key={subjectIndex}>
                        <Tooltip title={subjectObj.name} placement="left">
                          <NavLink to={`${path}/subject/${subjectObj.id}`} className="parent" >
                            <UilBooks />
                            <div style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis', width: '150px' }}>
                              {subjectObj.name}
                            </div>
                          </NavLink>
                        </Tooltip>
                      </li>
                    )
                  }
                </ul>
                :
                menuVisible &&
                <ul className="subMenu">
                  <li>
                    <Link to="#">
                      Subjects Not Found
                    </Link>
                  </li>
                </ul>
            }
          </li>
          <li className="">
            <NavLink to={`${path}/interviews`} >
              Interviews
            </NavLink>
          </li>
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default StudentMenu;


const TopmenuSubjectLinks = ({ path, StudentSubjects, yearsem_code1, yearsem_code2, toggleMegamenu, addParentActive }) => {
  return (
    <>
      {
        StudentSubjects.length !== 0 && StudentSubjects.filter(sub => sub.yearsemester_code === yearsem_code1 || sub.yearsemester_code === yearsem_code2).sort((a, b) => a.subject_name > b.subject_name ? 1 : -1).map((obj, index) =>
          <li key={index} className='text-ellipsis' onClick={toggleMegamenu}>
            <NavLink onClick={addParentActive} to={`${path}/subject/${obj.subject_id}`}>
              <Tooltip title={obj.subject_name} placement="left">{obj.subject_name}</Tooltip>
              {/* {obj.subject_name} */}
              {/* <img src={subjectCompleteBadge} className='img-grayscale'/> */}
            </NavLink>
          </li>
        )
      }
    </>
  );
}