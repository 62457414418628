import actions from './actions';

const {

  GET_STUDENT_INTERVIEWS_BEGIN,
  GET_STUDENT_INTERVIEWS_SUCCESS,
  GET_STUDENT_INTERVIEWS_ERR,

  GET_INTERVIEW_ATTEMPTS_BEGIN,
  GET_INTERVIEW_ATTEMPTS_SUCCESS,
  GET_INTERVIEW_ATTEMPTS_ERR,


} = actions;

const studentInterviewsInitialState = {
  data: [],
  loading: false,
  error: null,
}

const interviewAttemptsInitialState = {
  data: [],
  loading: false,
  error: null,
}


const StudentInterviewsReducer = (state = studentInterviewsInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_STUDENT_INTERVIEWS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_INTERVIEWS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_STUDENT_INTERVIEWS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getInterviewAttemptsReducer = (state = interviewAttemptsInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_INTERVIEW_ATTEMPTS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_INTERVIEW_ATTEMPTS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_INTERVIEW_ATTEMPTS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { StudentInterviewsReducer, getInterviewAttemptsReducer };
