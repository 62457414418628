import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem, setItem } from '../../utility/localStorageControl';

const {
  fsReadBegin,
  fsReadSuccess,
  fsReadErr,
} = actions;

const fsDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(fsReadBegin());
      const response = await DataService.get(`/fs-master`);
      const token = getItem('access_token');

      if (token && response.status === 200 && response.data !== null && response.data.Error === false) {
        await dispatch(fsReadSuccess(response.data.payload));
        if (response.data.payload.length !== 0) {
          const activeFSObj = response.data.payload.sort((a, b) => a.fsyear < b.fsyear ? 1 : -1).filter((obj) => obj.status === "ACTIVE")
          if(activeFSObj.length !== 0) {
            setItem("activeFSid",activeFSObj[0].id)
            setItem("activeFSName",activeFSObj[0].name)
          }
        }
      } else {
        if (response.data.Error === true && response.data.statusCode === 401) {          
          dispatch(logOut())
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(fsReadErr(err));
    }
  };
};

export {
  fsDataRead    
};

