import './spinstyle.css'
function CustomSpinner({ Message }) {
    return (
        <div className='spinner-container'>
            <div className='container'>
                <div className='cube'>
                    <div className='sides'>
                        <div className='top'></div>
                        <div className='right'></div>
                        <div className='bottom'></div>
                        <div className='left'></div>
                        <div className='front'></div>
                        <div className='back'></div>
                    </div>
                </div>
            </div>
            {
                Message !== null ?
                    <div className='loader-text'>
                        {Message}
                    </div>
                    :
                    <div className='loader-text'>
                        Loading... Please wait.
                    </div>
            }
        </div>
    );
}

export default CustomSpinner