import actions from './actions';

const {
  READ_TOPICS_BEGIN,
  READ_TOPICS_SUCCESS,
  READ_TOPICS_ERR,

  READ_TOPIC_CONTENT_BEGIN,
  READ_TOPIC_CONTENT_SUCCESS,
  READ_TOPIC_CONTENT_ERR,

  FLAG_UPDATE_BEGIN,
  FLAG_UPDATE_SUCCESS,
  FLAG_UPDATE_ERR,
  
  UPDATE_CONTENT_BEGIN,
  UPDATE_CONTENT_SUCCESS,
  UPDATE_CONTENT_ERR,
  
  DELETE_CONTENT_BEGIN,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_ERR,

} = actions;

const initialState = {
  data: [],    
  loading: false,
  error: null,
};

const initialContentState = {
  data: null,    
  loading: false,
  error: null,
};

const initialFlagUpdateState = {
  data: null,    
  loading: false,
  error: null,
};

const updateContentInitialsState = {
  data: null,    
  loading: false,
  error: null,
};

const deleteContentInitialsState = {
  data: null,    
  loading: false,
  error: null,
};

const TOPICSReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case READ_TOPICS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case READ_TOPICS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case READ_TOPICS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TOPICContentReducer = (state = initialContentState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case READ_TOPIC_CONTENT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case READ_TOPIC_CONTENT_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case READ_TOPIC_CONTENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const AssessmentFlagUpdateReducer = (state = initialFlagUpdateState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case FLAG_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FLAG_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case FLAG_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const UpdateContentReducer = (state = updateContentInitialsState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case UPDATE_CONTENT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_CONTENT_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case UPDATE_CONTENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const DeleteContentReducer = (state = deleteContentInitialsState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case DELETE_CONTENT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case DELETE_CONTENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { TOPICSReducer, TOPICContentReducer, AssessmentFlagUpdateReducer, UpdateContentReducer, DeleteContentReducer };
