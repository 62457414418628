export const READ_RECENTDATA_BEGIN = 'READ_RECENTDATA_BEGIN';
export const READ_RECENTDATA_SUCCESS = 'READ_RECENTDATA_SUCCESS';
export const READ_RECENTDATA_ERROR = 'READ_RECENTDATA_ERROR';

export const readRecentDataBegin = () => ({
    type: READ_RECENTDATA_BEGIN,
});

export const readRecentDataSuccess = (data) => ({
    type: READ_RECENTDATA_SUCCESS,
    data,
});

export const readRecentDataError = (err) => ({
    type: READ_RECENTDATA_ERROR,
    err,
});
