import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message } from 'antd';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';


const {
  ReadTopicsBegin,
  ReadTopicsSuccess,
  ReadTopicsErr,

  ReadTopicContentBegin,
  ReadTopicContentSuccess,
  ReadTopicContentErr,

  flagUpdateBegin,
  flagUpdateSuccess,
  flagUpdateErr,
  
  UpdateContentBegin,
  UpdateContentSuccess,
  UpdateContentErr,
  
  DeleteContentBegin,
  DeleteContentSuccess,
  DeleteContentErr,
} = actions;


const topicsDataRead = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(ReadTopicsBegin());
      const query = await DataService.get(`/topicmaster/subject/${values.subjectId}/${values.unitId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(ReadTopicsSuccess(query.data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(ReadTopicsSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(ReadTopicsSuccess([]));
        }
      }
      else {
        await dispatch(ReadTopicsSuccess([]));
        // await dispatch(topicsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(ReadTopicsErr(err));
    }
  };
};

const getTopicContentByTopic = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(ReadTopicContentBegin());
      const query = await DataService.get(`/topicmaster/content/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {        
        await dispatch(ReadTopicContentSuccess(query.data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(ReadTopicContentSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(ReadTopicContentSuccess(null));
        }
      }
      else {
        await dispatch(ReadTopicContentSuccess(null));
        // await dispatch(topicsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(ReadTopicContentErr(err));
    }
  };
}


const UpdateTopicAssessmentFlag = (postdata) => {
  return async (dispatch) => {
    try {
      await dispatch(flagUpdateBegin());
      const query = await DataService.post(`/topicmaster/uaflag`,postdata);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(flagUpdateSuccess(query.data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(flagUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(flagUpdateSuccess(query.data));
        }
      }
      else {
        await dispatch(flagUpdateSuccess(null));
        // await dispatch(topicsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(flagUpdateErr(err));
    }
  };
};


const UpdateContent = (postdata) => {
  return async (dispatch) => {
    try {
      await dispatch(UpdateContentBegin());
      const query = await DataService.put(`/topicmaster/updatecontent`,postdata);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        message.success(data.message)
        await dispatch(UpdateContentSuccess(query.data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(UpdateContentSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(UpdateContentSuccess(query.data));
        }
      }
      else {
        await dispatch(UpdateContentSuccess(null));
        // await dispatch(topicsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(UpdateContentErr(err));
    }
  };
};

const DeleteContent = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(DeleteContentBegin());
      const query = await DataService.put(`/topicmaster/removecontent/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        message.success(data.message)
        await dispatch(DeleteContentSuccess(query.data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(DeleteContentSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(DeleteContentSuccess(query.data));
        }
      }
      else {
        await dispatch(DeleteContentSuccess(null));
        // await dispatch(topicsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(DeleteContentErr(err));
    }
  };
};

export {
  topicsDataRead,
  getTopicContentByTopic,
  UpdateTopicAssessmentFlag,
  UpdateContent,
  DeleteContent
};
