// import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { AuthReducer, ForgotPasswordReducer, VerifyOTPReducer, ResetPassReducer, MessageReducer, CollegeWiseMessageReducer } from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { SUBJECT_UNITSReducer, SUBJECTReducer, UNITReducer, TeacherRegSubjectsReducer } from './teacherSubjectsUnits/reducers'
import { TOPICSReducer, TOPICContentReducer, AssessmentFlagUpdateReducer, UpdateContentReducer, DeleteContentReducer } from './teacherTopicsMenu/reducers'
import { UploadContentReducer } from './uploadContent/reducers'
import { ReviewContentReducer, ReviewChunkUpdateReducer, DeleteChunkReducer } from './reviewContent/reducers'
import { CreateTopicReducer, UpdateTopicDetailsReducer, TOPICSingleReducer, TOPICSTATUSReducer, TOPICSByUnitReducer, TopicClearReducer, StudentTopicsByUnitReducer } from './topics/reducers';
import { ReadQAReducer, postResponseReducer, UpdateQAReducer, ReadAllQAByTopicReducer, DeleteQAReducer, AnswerEvaluateReducer, GETATTEMPTEDQAReducer } from './generateQuestions/reducers';
import { postQuizResponseReducer, ReadAllQuizByTopicReducer, UpdateQuizReducer, DeleteQuizReducer, ReadAllQuizReducer } from './generateQuiz/reducers';
import { ReadStudentSubjectsReducer, ReadSubjectUnitsReducer } from './studentSubjectsUnits/reducers';
import { getNgrokUrlReducer } from './schedule/reducers';
import { ProgressReducer } from './teacherDashboard/reducers';
import { QAFReducer } from './QAFeedback/reducers';
import { ContentFeedbackReducer, GetContentFBQReducer, VerifyFeedbackReducer, PostFeedbackReducer } from './contentFeedback/reducers';
import { RecentActionsDataReducer } from './recentActions/reducers';
import { PdfFileReducer } from './pdf/reducers';
import { ReadAllQuizToAttemptReducer, SaveQuizReducer, SubmitAllQuizReducer, GetQuizResultReducer } from './getQuiztoAttempt/reducers';
import { StudentRecentActionsDataReducer } from './studentRecentActions/reducers';
import { StudentBadgesDataReducer, ReadBadgeByIdReducer, ReadSubBadgeByIdReducer } from './studentBadgesEarned/reducers';
import { StudentWeeklyTopicsReducer } from './studentWeeklyTopics/reducers';
import { TessellatorTopicsReducer, TessellatorTopicPogramsReducer, MapTessellatorTopicReducer, MapTessellatorTopicToNullReducer } from './tessellatorTopics/reducers';
import { TeacherMonitorReducer } from './teacher-monitor/reducers';
import { getSectionsReducer } from './section/reducers';
import { ChangePasswordReducer } from './changepassword/reducers';
import { getLeaderboardReducer } from './leaderboard/reducers';
import { DailyLoginCountReducer, CurrentweekYearwiseReducer, TodaysReadAssessmentBadgesReducer } from './studentrelatedcount/reducers';
import LayoutChangeReducer from './themeLayout/reducers';
import { AnswersReducer, ManualEvaluationReducer, QaReportsReducer } from './answers/reducers';
import { TranscribeReducer } from './transcribe/reducers';
import { GetAssessmentLoginsReducer, updateStudentFlagReducer } from './assessmentLogins/reducers';
import { FSReadReducer } from './fs/reducers';
import { StudentInterviewsReducer, getInterviewAttemptsReducer } from './interview/reducers';

const rootReducers = combineReducers({
  // fb: firebaseReducer,
  fs: firestoreReducer,
  auth: AuthReducer,
  ForgotPass: ForgotPasswordReducer,
  VerifyOTP: VerifyOTPReducer,
  ResetPassRes: ResetPassReducer,
  ChangeLayoutMode,
  ChangePassword: ChangePasswordReducer,
  msg: MessageReducer,
  Layout: LayoutChangeReducer,

  // teacher reducers
  CreateTopicRes: CreateTopicReducer,
  UpdateTopicDetailsRes: UpdateTopicDetailsReducer,
  ProgressData: ProgressReducer,
  QAFeedbackData: QAFReducer,
  CFData: ContentFeedbackReducer,
  RecentActions: RecentActionsDataReducer,
  DailyLoginCount: DailyLoginCountReducer,
  CurrentWeekYrwise: CurrentweekYearwiseReducer,
  NgrokUrl: getNgrokUrlReducer,
  TodaysBadgesCount: TodaysReadAssessmentBadgesReducer,
  AssessmentLogins: GetAssessmentLoginsReducer,
  AssessmentStudentFlagUpdateRes: updateStudentFlagReducer,
  fsData: FSReadReducer,

  SubjectsUnits: SUBJECT_UNITSReducer,
  Subject: SUBJECTReducer,
  Unit: UNITReducer,
  Topics: TOPICSReducer,
  UploadContentRes: UploadContentReducer,
  ChunksForReview: ReviewContentReducer,
  updatedChunk: ReviewChunkUpdateReducer,
  DeleteChunkRes: DeleteChunkReducer,
  singleTopic: TOPICSingleReducer,
  TopicStatus: TOPICSTATUSReducer,
  GenerateQARes: postResponseReducer,
  QA: ReadQAReducer,
  QAUpdateRes: UpdateQAReducer,
  AllQAByTopic: ReadAllQAByTopicReducer,
  DeleteQARes: DeleteQAReducer,
  AnsEvaluateRes: AnswerEvaluateReducer,
  GenerateQuizRes: postQuizResponseReducer,
  AllQuiz: ReadAllQuizReducer,
  quizUpdateRes: UpdateQuizReducer,
  DeleteQuizRes: DeleteQuizReducer,
  AllQuizByTopic: ReadAllQuizByTopicReducer,
  TopicClearRes: TopicClearReducer,
  TessellatorTopics: TessellatorTopicsReducer,
  TessellatorTopicPrograms: TessellatorTopicPogramsReducer,
  MapTessellatorTopicRes: MapTessellatorTopicReducer,
  MapTsTopicToNullRes: MapTessellatorTopicToNullReducer,
  TeacherMonitor: TeacherMonitorReducer,
  Answers: AnswersReducer,
  Sections: getSectionsReducer,
  TopicContent: TOPICContentReducer,
  TranscribeRes: TranscribeReducer,
  AssessmentFlagRes: AssessmentFlagUpdateReducer,
  TeacherRegSubjects: TeacherRegSubjectsReducer,
  ManualEvalRes: ManualEvaluationReducer,
  StuQaReports: QaReportsReducer,
  UpdateContentRes: UpdateContentReducer,
  DeleteContentRes: DeleteContentReducer,

  // student reducers
  collegeWiseNotce: CollegeWiseMessageReducer,
  StudentSubjects: ReadStudentSubjectsReducer,
  Units: ReadSubjectUnitsReducer,
  UnitTopics: TOPICSByUnitReducer,
  CFQuestions: GetContentFBQReducer, // content feedback questions
  VerifyRes: VerifyFeedbackReducer,
  FBPostRes: PostFeedbackReducer,
  PdfFile: PdfFileReducer,
  QuiztoAttempt: ReadAllQuizToAttemptReducer,
  QuizSaveRes: SaveQuizReducer,
  SubmitAllQuizRes: SubmitAllQuizReducer,
  QuizResult: GetQuizResultReducer,
  StudentRecentActions: StudentRecentActionsDataReducer,
  StudentBadges: StudentBadgesDataReducer,
  SingleBadgeImg: ReadBadgeByIdReducer,
  SubBadges: ReadSubBadgeByIdReducer,
  StudentWeeklyTopics: StudentWeeklyTopicsReducer,
  studentTopicsByUnit: StudentTopicsByUnitReducer,
  leaderboardResults: getLeaderboardReducer,
  AttemptedQA: GETATTEMPTEDQAReducer,

  studentInterviews: StudentInterviewsReducer,
  interviewAttempts: getInterviewAttemptsReducer

});

export default rootReducers;
