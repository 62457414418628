const actions = {

  TRANSCRIBE_BEGIN: 'TRANSCRIBE_BEGIN',
  TRANSCRIBE_SUCCESS: 'TRANSCRIBE_SUCCESS',
  TRANSCRIBE_ERR: 'TRANSCRIBE_ERR',


  transcribeBegin: () => {
    return {
      type: actions.TRANSCRIBE_BEGIN,
    };
  },

  transcribeSuccess: (data) => {    
    return {
      type: actions.TRANSCRIBE_SUCCESS,      
      data,
    };
  },

  transcribeErr: (err) => {
    return {
      type: actions.TRANSCRIBE_ERR,
      err,
    };
  },
};

export default actions;
