import React, { useLayoutEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, TableDefaultStyle } from '../styled';
import { Link, NavLink, Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { NoteCardWrap, NoteNav, NoteWrapper } from '../note/style';
import { Col, Result, Row, Skeleton, Spin, Table } from 'antd';
import { Button } from '../../components/buttons/buttons';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { FixedSidebar, SidebarWrap } from '../teacher/styles/sidebarstyle';
import { Suspense } from 'react';
import TopicsList from './TopicsList';
import { useEffect } from 'react';
import { getSubjectById } from '../../redux/teacherSubjectsUnits/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { subjectUnitsDataRead } from '../../redux/studentSubjectsUnits/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import TopicActions from '../../redux/topics/actions'
import unitCompleteBadge from '../../static/img/UNIT-COMPLETE/UNIT-COMPLETE-16X16.png'
import { subjectBadgesDataRead } from '../../redux/studentBadgesEarned/actionCreator';
import SelectUnit from './SelectUnit'
import { getItem, setItem } from '../../utility/localStorageControl';

const { topicsReadSuccess } = TopicActions


function Units() {
    const activeUnitIndex = getItem('activeunit')

    const { subjectId } = useParams()

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [sideBarState, setSidebarState] = useState({
        responsive: 0,
        collapsed: false,
        visible: false,
        modalType: 'primary',
    });

    const { SubjectInfo, subjectLoading, Units, unitsLoading, SubBadges, SubBadgesLoading } = useSelector((state) => {
        return {
            SubjectInfo: state.Subject.data,
            subjectLoading: state.Subject.loading,
            Units: state.Units.data,
            unitsLoading: state.Units.loading,
            SubBadges: state.SubBadges.data,
            SubBadgesLoading: state.SubBadges.loading
        };
    });

    useEffect(() => {
        dispatch(getSubjectById(subjectId))
        dispatch(subjectUnitsDataRead(subjectId))
        dispatch(topicsReadSuccess(null))
        dispatch(subjectBadgesDataRead(subjectId))
    }, [subjectId])

    const { responsive, collapsed } = sideBarState;

    useLayoutEffect(() => {
        function updateSize() {
            const width = window.innerWidth;
            setSidebarState({ responsive: width });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const toggleCollapsed = () => {
        setSidebarState({
            ...sideBarState,
            collapsed: !collapsed,
        });
    };

    useEffect(() => {
        // NavigateToPath()
    }, [Units])

    // const NavigateToPath = async () => {
    //     if (Units.length !== 0) {
    //         let sortedArray = await Units.sort((a, b) => a.unitName > b.unitName ? 1 : -1)
    //         if (activeUnitIndex === undefined && sortedArray.length !== 0) {
    //             NavigateDynamic(sortedArray[0])
    //         }
    //         else if (activeUnitIndex !== undefined && sortedArray.length !== 0) {
    //             NavigateDynamic(sortedArray[activeUnitIndex])
    //         }
    //     }
    // }

    // const NavigateDynamic = async (unitObj) => {
    //     if (unitObj !== null || unitObj !== undefined) {
    //         navigate(`unit/${unitObj.unitId}`)
    //     }
    //     else {
    //         let sortedArray = await Units.sort((a, b) => a.unitName > b.unitName ? 1 : -1)
    //         navigate(`unit/${sortedArray[0].unitId}`)
    //     }
    // }


    return (
        <Main>
            {
                subjectLoading || unitsLoading ?
                    <div className='spin'>
                        <CustomSpinner />
                    </div>
                    :
                    <div>
                        <PageHeader className="ninjadash-page-header-main" title={`${SubjectInfo !== null ? SubjectInfo.name : ''}`} />
                        <>
                            <NoteWrapper>
                                {
                                    Units.length === 0 ?
                                        <Row gutter={25}>
                                            <Col xs={24}>
                                                <Cards headless>
                                                    <NoteCardWrap className='spin'>
                                                        <Result
                                                            status='warning'
                                                            title="Units not found"
                                                            subTitle="Please select a subject from the top menu to proceed."
                                                        />
                                                    </NoteCardWrap >
                                                </Cards>
                                            </Col>
                                        </Row>
                                        :
                                        <Row gutter={25}>
                                            <Col xxl={6} lg={7} md={8} xs={24}>
                                                {responsive <= 767 && (
                                                    <div className="sidebar-trier-wrap text-center">
                                                        <Button type="link" className="sidebar-trigger" style={{ marginTop: 0 }} onClick={toggleCollapsed}>
                                                            {collapsed ? <UilAngleLeft /> : <UilAngleRight />}
                                                        </Button>
                                                    </div>
                                                )}
                                                {responsive > 767 ? (
                                                    <div className="sidebar-card-student">
                                                        <Cards headless>
                                                            <div className="note-sidebar-bottom">
                                                                <NoteNav>
                                                                    <Heading as='h3' className='text-center'>Units</Heading>
                                                                    <ul className="ninjadash-taskApp-sidebar__nav">
                                                                        {
                                                                            Units.length !== 0 && Units.sort((a, b) => a.name > b.name ? 1 : -1).map((unit, index) =>
                                                                                <li key={index}>
                                                                                    <NavLink to={`unit/${unit.id}`} onClick={() => setItem('activeunit', index)}>
                                                                                        <span className="nav-text" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: `${responsive > 1435 ? '180px' : ((responsive <= 1435 && responsive > 1275) || (responsive <= 991 && responsive > 936)) ? '150px' : ((responsive <= 1275 && responsive > 1165) || (responsive <= 936 && responsive > 876)) ? '130px' : ((responsive <= 1165 && responsive > 1130) || (responsive <= 876 && responsive > 846)) ? '120px' : ((responsive <= 1130 && responsive > 1090) || (responsive <= 846 && responsive > 816)) ? '110px' : ((responsive <= 1090 && responsive > 1045) || (responsive <= 816 && responsive > 786)) ? '100px' : ((responsive <= 1045 && responsive > 991) || (responsive <= 786 && responsive > 767)) && '85px'}` }}>
                                                                                            {unit.name}
                                                                                        </span>
                                                                                        <div style={{ marginLeft: 90 }}><img src={unitCompleteBadge} className='img-grayscale' /></div>
                                                                                    </NavLink>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </NoteNav>
                                                            </div>
                                                        </Cards>
                                                        <Cards headless>

                                                            <div className="note-sidebar-bottom">
                                                                <NoteNav>
                                                                    <Heading as='h3' className='text-center'>Badges</Heading>
                                                                    {
                                                                        SubBadgesLoading ?
                                                                            <div>
                                                                                <Skeleton active avatar className='mt-25' />
                                                                                <Skeleton active avatar className='mt-25' />
                                                                            </div>
                                                                            :
                                                                            <div className='mt-25'>
                                                                                {
                                                                                    SubBadges !== null ?
                                                                                        <TableDefaultStyle className="ninjadash-having-header-bg" >
                                                                                            <Table
                                                                                                showHeader={false}
                                                                                                pagination={false}
                                                                                                columns={[
                                                                                                    {
                                                                                                        title: "",
                                                                                                        dataIndex: 'badge',
                                                                                                        key: 'badge',
                                                                                                    },
                                                                                                    {
                                                                                                        title: "",
                                                                                                        dataIndex: 'title',
                                                                                                        key: 'title',
                                                                                                        align: 'left'
                                                                                                    },
                                                                                                    {
                                                                                                        title: "",
                                                                                                        dataIndex: 'count',
                                                                                                        key: 'count',
                                                                                                    },
                                                                                                ]}
                                                                                                dataSource={[
                                                                                                    {
                                                                                                        key: 1,
                                                                                                        badge: <img src={process.env.REACT_APP_API_ENDPOINT + SubBadges.topicReadBadge} width={16} />,
                                                                                                        title: SubBadges.topicreadbadgename,
                                                                                                        count: SubBadges.topicreadcount === null ? 0 : SubBadges.topicreadcount
                                                                                                    },
                                                                                                    // {
                                                                                                    //     key: 2,
                                                                                                    //     badge: <img src={process.env.REACT_APP_API_ENDPOINT + SubBadges.topicCompleteBadge} width={16} />,
                                                                                                    //     title: SubBadges.topiccompletebadgename,
                                                                                                    //     count: SubBadges.topiccompletecount === null ? 0 : SubBadges.topiccompletecount
                                                                                                    // },
                                                                                                    {
                                                                                                        key: 3,
                                                                                                        badge: SubBadges.unitbadgename && <img src={process.env.REACT_APP_API_ENDPOINT + SubBadges.unitCompleteBadge} width={16} />,
                                                                                                        title: SubBadges.unitbadgename,
                                                                                                        count: SubBadges.unitcompletecount === null ? 0 : SubBadges.unitcompletecount
                                                                                                    },
                                                                                                    {
                                                                                                        key: 4,
                                                                                                        badge: SubBadges.subjectbadgename && <img src={process.env.REACT_APP_API_ENDPOINT + SubBadges.subCompleteBadge} width={16} />,
                                                                                                        title: SubBadges.subjectbadgename,
                                                                                                        count: SubBadges.subjectcompletecount === null ? 0 : SubBadges.subjectcompletecount
                                                                                                    },
                                                                                                ]}
                                                                                            />
                                                                                        </TableDefaultStyle>
                                                                                        :
                                                                                        <div className='text-center'>
                                                                                            No badges earned yet
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                    }
                                                                </NoteNav>
                                                            </div>
                                                        </Cards>

                                                    </div>
                                                ) : (
                                                    <FixedSidebar className={collapsed ? 'show' : 'hide'}>
                                                        <Link to="#" type="link" className="trigger-close" onClick={toggleCollapsed}>
                                                            <UilTimes />
                                                        </Link>
                                                        <SidebarWrap className="mb-30">
                                                            <div className="ninjadash-taskApp-sidebar">
                                                                <Heading as='h3' className='text-center'>Units</Heading>
                                                                <ul className="ninjadash-taskApp-sidebar__nav">
                                                                    {
                                                                        Units.length === 0 ? <div className='text-center mt-25' style={{ fontSize: '18px' }}>Units Not Found</div> : Units.sort((a, b) => a.name > b.name ? 1 : -1).map((unit, index) =>
                                                                            <li key={index} className="ninjadash-taskApp-sidebar__nav--item d-flex justify-content-between">
                                                                                <NavLink className="ninjadash-taskApp-sidebar__nav--link" to={`unit/${unit.id}`} onClick={() => { setItem('activeunit', index); toggleCollapsed() }}>
                                                                                    <span className="nav-text" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '180px', width: '140px' }}>
                                                                                        {unit.name}
                                                                                    </span>
                                                                                    <div className='d-inline'><img src={unitCompleteBadge} className='img-grayscale' /></div>
                                                                                </NavLink>
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </SidebarWrap>
                                                    </FixedSidebar>
                                                )}
                                            </Col>
                                            <Col xxl={18} lg={17} md={16} xs={24}>
                                                <Suspense
                                                    fallback={
                                                        <div className="">
                                                            <CustomSpinner />
                                                        </div>
                                                    }
                                                >
                                                    {
                                                        Units.length !== 0 &&
                                                        <Routes>
                                                            {/* <Route path="/" element={<Navigate to={`unit/${activeUnitIndex ? activeUnitIndex : Units[0].unitId}`} replace={true} />} /> */}
                                                            <Route path="/" element={<SelectUnit />} />
                                                            <Route path={`unit/:unitId`} element={<TopicsList />} />
                                                        </Routes>
                                                    }
                                                </Suspense>
                                                {/* {
                                topics.length !== 0 && topics.map(Obj =>
                                    <Cards title={`Topics of ${Obj.unitName}`} key={Obj.unitId} >
                                        <Row className='mb-20'>
                                            {
                                                Obj.topics.length !== 0 && Obj.topics.map(topic =>
                                                    <Col xs={12} key={topic.id} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                                        <Link to={`${Obj.unitName}/${topic.name}`} style={{ color: '#8c8c8c' }} className='underline-hover'>
                                                            {topic.name}
                                                        </Link>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Cards>
                                )
                            } */}
                                            </Col>
                                        </Row>
                                }
                            </NoteWrapper>
                        </>
                    </div>
            }
        </Main>
    );
}

export default Units;